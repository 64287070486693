import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, Project } from '../models';
import { DynamicLogicSet, LogicField } from '../models/logic-set';
import { Site } from '../models/site';
import { MapLogicDataService } from './data-services';
import { FieldsService } from './fields.service';
import { MapLogicServiceModel } from './logic-services/models/map';

@Injectable({
    providedIn: 'root'
})
export class MapLogicService {
    private _mapLogic: DynamicLogicSet[] = [];
    model: MapLogicServiceModel;
    mapLogic: BehaviorSubject<DynamicLogicSet[]> = new BehaviorSubject<DynamicLogicSet[]>(null);
    newMapLogic: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    mapLogicLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    currentMapLogic: DynamicLogicSet;

    constructor(
        private _snackBar: MatSnackBar,
        private _fieldsService: FieldsService,
        private _mapLogicData: MapLogicDataService,
    ) {
        this.initModel();
    }

    initModel(): void {
        if (this.model === undefined) this.model = new MapLogicServiceModel();
    }

    setMapLogic(mapLogic: DynamicLogicSet[]): void {
        this.setCurrentMapLogic(mapLogic);
    }

    private setCurrentMapLogic(mapLogic: DynamicLogicSet[]): void {
        this.mapLogic.next(mapLogic);
        this._mapLogic = mapLogic;
        this.model.mapLogic = mapLogic;
    }

    resetMapLogic(): void {
        this.mapLogic.next(this._mapLogic);
    }

    disconnectLogic(): void {
        this._mapLogic = [];
        this.mapLogic.next(null);
        this.newMapLogic.next(null);
        this.setCurrentMapLogic(null);
    }

    updateMapLogic(app: App, mapLogic: DynamicLogicSet, filter: string[] = null): Observable<any> {
        const result$ = new Subject<any>();

        if (filter)
            this._mapLogicData.updateMapLogicWithFilter(app, mapLogic, filter)
                .subscribe({
                    next: (response: any) => {
                        result$.next(response);
                        result$.complete();
                    }
                });
        else
            this._mapLogicData.updateMapLogicWithoutFilter(app, mapLogic)
                .subscribe({
                    next: (response: any) => {
                        result$.next(response);
                        result$.complete();
                    }
                });

        return result$.asObservable();
    }

    getMapLogicByProjId(appId: App, project: Project): void {
        if (!project.id)
            return;

        this.mapLogicLoading.next(true);

        this._mapLogicData.getMapLogicByProjId(appId, project)
            .subscribe({
                next: (response: any) => {
                    this._mapLogic = response;
                    this.setMapLogic(response);
                },
                error: () => {
                    this._mapLogic = [];
                    this.disconnectLogic();
                    this._snackBar.open('Error getting map logic', '', { duration: 2000 });
                }
            })
            .add(() => {
                this.mapLogicLoading.next(false);
            });
    }

    getAllRecordsByAppId(app: App): void {
        this.mapLogicLoading.next(true);

        this._mapLogicData.getAllRecordsByAppId(app)
            .subscribe({
                next: (response: any) => {

                    this._mapLogic = response;
                    this.setMapLogic(response);

                }, error: () => {

                    this._mapLogic = [];
                    this.disconnectLogic();
                    this._snackBar.open('Error getting map logic record', '', {
                        duration: 2000,
                    });

                }
            })
            .add(() => {
                this.mapLogicLoading.next(false);
            });
    }

    updateMapLogicLocally(mapLogic: DynamicLogicSet): void {
        if (this._mapLogic.find(u => u.id === mapLogic.id)) {
            let index = this._mapLogic.findIndex(u => u.id === mapLogic.id);
            this._mapLogic[index] = mapLogic;
        }
        else {
            this._mapLogic.push(mapLogic);
        }
        this.resetMapLogic();
    }

    evaluateMapLogic(logicSets: DynamicLogicSet[], site: Site): any {
        const resultSets = logicSets.map(logicSet => {
            let logicStatus = false;
            let fieldStatus = [];
            logicSet.fields.forEach(field => {
                let status = false;

                const value = this.checkValueType(field, site);

                const getFieldValue = () => {
                    if (site.data && site.data[field.id]) {
                        if (parseInt(site.data[field.id])) {
                            if (moment(site.data[field.id], "YYYY-MM-DD", true).isValid()) {
                                return moment(site.data[field.id], "YYYY-MM-DD", true);
                            }
                            else {
                                return parseInt(site.data[field.id]);
                            }
                        }
                        else {
                            return site.data[field.id];
                        }
                    }
                    else {
                        return null;
                    }
                };
                const fieldValue = getFieldValue();

                switch (field.operator.text) {
                    case 'Has Value':
                        status = fieldValue !== null || fieldValue !== undefined || fieldValue !== '';
                        break;
                    case 'Equals':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = value.isSame(fieldValue);
                        }
                        else {
                            if (fieldValue === value) {
                                status = true;
                            }
                        }
                        break;
                    case 'Not equals':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = !value.isSame(fieldValue);
                        }
                        else {
                            if (fieldValue !== value) {
                                status = true;
                            }
                        }
                        break;
                    case 'Has No Value':
                        status = fieldValue === null || fieldValue === undefined || fieldValue === '';
                        break;
                    case 'Less than':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = fieldValue.isBefore(value);
                        }
                        else {
                            if (fieldValue < value) {
                                status = true;
                            }
                        }
                        break;
                    case 'Less than/Equal to':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = fieldValue.isSameOrBefore(value);
                        }
                        else {
                            if (fieldValue <= value) {
                                status = true;
                            }
                        }
                        break;
                    case 'Greater than':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = fieldValue.isAfter(value);
                        }
                        else {
                            if (fieldValue > value) {
                                status = true;
                            }
                        }
                        break;
                    case 'Greater than/Equal to':
                        if (value && fieldValue) {
                            if (moment.isMoment(value) || moment.isMoment(fieldValue))
                                status = fieldValue.isSameOrAfter(value);
                        }
                        else {
                            if (fieldValue >= value) {
                                status = true;
                            }
                        }
                        break;
                }

                fieldStatus.push({
                    fieldId: field.id,
                    status: status,
                    conjunction: field.conjunction
                });
            });

            let evaluation = new String;

            for (let i = 0; i < fieldStatus.length; i++) {
                //Check if the current field is being conjoined from the previous field
                switch (fieldStatus[i].conjunction) {
                    case 'AND':
                        evaluation += " && ";
                        break;
                    case 'OR':
                        evaluation += " || ";
                        break;
                }
                evaluation += fieldStatus[i].status;
            }

            logicStatus = new Function('return ' + evaluation)();

            return {
                value: logicSet.value,
                status: logicStatus
            };
        });

        return resultSets.find(result => result.status);
    }

    private checkValueType(field: LogicField, site: Site): any {
        let value;
        let stringValue = field.value.toString();
        switch (field.valueType) {
            case "Value":
                if (parseInt(stringValue)) {
                    value = parseInt(stringValue);
                }
                else {
                    value = field.value;
                }
                break;
            case "Field":
                if (site.data) {
                    value = site.data[field.targetId];
                }
                else {
                    value = null;
                }
                let tempField = this._fieldsService.getField(field.targetId);
                if (tempField?.FieldType.options?.format === 'date') {
                    value ? value : value = moment();
                    value.add(field.addToValue, 'days');
                    value = moment(value, "YYYY-MM-DD").utc();
                }
                else if (tempField?.FieldType?.type === 'number') {
                    value ? value : value = 0;
                    value += field.addToValue;
                }
                break;
            case "Date":
                value = new Date(field.date);
                value.setDate(value.getDate() + field.addToValue);
                value = moment(value, "YYYY-MM-DD").utc();
                break;
            case "Current Day":
                value = new Date();
                value.setDate(value.getDate() + field.addToValue);
                value = moment(value, "YYYY-MM-DD").utc();
                break;
        }

        return value;
    }

    deleteItem(app: App, mapLogicId: any): Observable<any> {
        const result$ = new Subject<any>();

        this._mapLogicData.deleteItem(app, mapLogicId)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                },
                error: (error: any) => {
                    result$.error(error);
                }
            });

        return result$.asObservable();
    }
}
