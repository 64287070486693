import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Observable } from 'rxjs';
import { AuthService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _authService: AuthService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean {
        const token = sessionStorage.getItem('token');
        if (token) {
            const decoded = jwt_decode<any>(token);
            if (decoded.exp > Date.now() / 1000) {
                if (decoded.id !== null || decoded.id !== undefined || decoded.id !== '') {
                    return true;
                }
            }
        }
        const url = route.parent.paramMap.get('url');
        this._authService.setReturnUrl(state.url);

        switch (url) {
            case null:
            case 'null':
                this._router.navigate(['home']);
                break;
            default:
                this._router.navigate([`app/${url}/login`]);
                break;
        }
    }

}
