import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { App, DynamicLogicSet, Project, User } from '../models';
import { ProjectsDataService } from './data-services';
import { LogicService } from './logic-services';
import { BreadcrumbLogicService } from './logic-services/breadcrumb.service';
import { FormElement, ProjectsServiceModel, Section, SmartsForm, UserProjectLists } from './logic-services/models';
import { RtsFormUtility, SchemaBuilderUtility, UISchemaBuilderUtility } from './utility-services';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService implements LogicService {

    private projectChanged = new Subject<boolean>();
    private dragAndDropUpdate = new Subject<boolean>();
    private selectedProjectChanged = new Subject<boolean>();
    projectsReady: Subject<boolean> = new Subject<boolean>();

    projectChanged$ = this.projectChanged.asObservable();
    dragAndDropUpdate$ = this.dragAndDropUpdate.asObservable();
    projectsReady$ = this.projectsReady.asObservable();
    selectedProjectChanged$ = this.selectedProjectChanged.asObservable();

    model: ProjectsServiceModel;
    project: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
    projectLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    projects: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>(null);
    projectsLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _projectDataService: ProjectsDataService,
        private _breadcrumbServcie: BreadcrumbLogicService,
        private _snackBar: MatSnackBar,
        private _rtsFormUtility: RtsFormUtility,
        private _uiSchemaBuilderUtility: UISchemaBuilderUtility,
        private _schemaBuilderUtility: SchemaBuilderUtility,
        private _router: Router,
    ) { }

    initModel(): void {
        if (this.model === undefined) {
            this.model = new ProjectsServiceModel();
            this.model.exportingLayout = false;
            this.model.projects = new Array<Project>();
            this.model.adminProjects = new Array<Project>();
            this.model.project = new Project();
            this.model.projectPreview = new Project();
            this.model.projectAdmins = new Array<User>();
            this.model.savingOrPublishing = false;
            this.model.uploadingLayout = false;
            this.model.projectLoading = false;
            this.model.projectsLoading = false;
        }
    }

    getProjectNew(): Project {
        if (this.model.currentProject === undefined)
            this.model.currentProject = new Project();

        if (this.model.currentProject.JSONForms === undefined)
            this.model.currentProject.JSONForms = new SmartsForm();

        if (this.model.currentProject.layoutDraft === undefined)
            this.model.currentProject.layoutDraft = new SmartsForm();

        if (this.model.currentProject.conditionalLogic === undefined)
            this.model.currentProject.conditionalLogic = new Array<DynamicLogicSet>();

        this.model.project = this.model.currentProject;

        return this.model.currentProject;
    }

    saveDraft(app: App, project: Project): void {
        this.model.savingOrPublishing = true;

        this._projectDataService.saveDraft(app, project)
            .subscribe({
                next: (project: Project) => {
                    this._snackBar.open('Layout draft saved', 'Close', { duration: 2000 });
                    this.model.project = project;
                },
                error: (error: any) => {
                    this._snackBar.open('Error saving layout', 'Close', { duration: 2000 });
                    console.log(error);
                },
                complete: () => { }
            })
            .add(() => { this.model.savingOrPublishing = false; });
    }

    setProjects(projects: Project[]): void {
        this.initModel();
        this.model.projects = projects;
        this.projects.next(this.model.projects);
        this.model.projectsLoading = false;
        this.projectsReady.next(true);
    }

    setProject(project: Project): void {
        this.model.currentProject = project;
        if (project) this._breadcrumbServcie.createBreadcrumbs(this.model.currentProject);
        this.project.next(this.model.currentProject);
        this.model.project = this.model.currentProject;
        this.projectChangedNotification();
        this.selectedProjectChangedNotification();
    }

    disconnectProject(): void {
        this.initModel();
        this.model.project = new Project();
        this.model.currentProject = new Project();
        this.project.next(null);
    }

    disconnectProjects(): void {
        this.model.projects = new Array<Project>();
        this.model.adminProjects = new Array<Project>();
        this.projects.next(null);
    }

    getProjectAdminsByProjId(app: App, project: Project): void {
        this._projectDataService.getProjectAdminsByProjectId(app, project)
            .subscribe({
                next: (projectAdmins: User[]) => {
                    this.model.projectAdmins = projectAdmins;
                },
                error: () => {
                    this._snackBar.open('Error loading application admins', 'Close', { duration: 2000, });
                },
                complete: () => { }
            });
    }

    getAllUserProjects(app: App): void {
        this.model.projectsLoading = true;
        this.projectsLoading.next(true);

        this._projectDataService.getAllUserProjects(app)
            .subscribe({
                next: (userProjectLists: UserProjectLists) => {
                    this.setProjects(userProjectLists.projects);
                    this.model.adminProjects = userProjectLists.projects.filter(project => {
                        return userProjectLists.adminProjectIds.includes(project.id);
                    });

                    this.setDefaultProject();

                    this.projectsReady.next(true);
                    this._snackBar.open(`${app.aliases.project.plural} Loaded`, 'Close', { duration: 2000 });
                },
                error: (error: any) => {
                    this._snackBar.open(`Error getting ${app.aliases.project.plural.toLowerCase()}`, 'Close', { duration: 2000 });
                    console.log(error);
                },
                complete: () => { }
            })
            .add(() => {
                this.projectsLoading.next(false);
            });
    }

    setDefaultProject(): void {
        let shouldUseAdminProjects: boolean = this._router.url.includes('/admin');

        let projects = shouldUseAdminProjects ? this.model.adminProjects.filter(proj => proj.active) : this.model.projects.filter(proj => proj.active);
        if (!projects?.length) {
            this.setProject(new Project());
            return;
        }
        if (projects?.length == 0 && !this.model.project.id) return;

        if (projects?.length == 0 && this.model.project.id) {
            this.setProject(new Project());
            return;
        }

        if (!this.model.project?.id) {
            this.setProject(projects[0]);
            return;
        }

        if (!projects.find(project => project.id == this.model.project.id)) {
            this.setProject(projects[0]);
            return;
        }
    }

    getProject(app: App, project: Project): void {
        this.projectLoading.next(true);
        this.model.projectLoading = true;

        this._projectDataService.getProjectByAppIdAndProjectId(app, project)
            .subscribe({
                next: (project: Project) => {
                    this.model.project = project;
                    this.setProject(project);
                    this._snackBar.open(`Loaded`, 'Close', { duration: 2000 });
                },
                error: (error: any) => {
                    this._snackBar.open(`Error getting ${app.aliases.project.singular.toLowerCase()} ${project.id}`, 'Close', { duration: 2000 });
                    console.log(error);
                },
                complete: () => { }
            })
            .add(() => {
                this.model.projectLoading = false;
                this.projectLoading.next(false);
            });
    }

    addUpdateProject(project: Project): void {
        if (this.model.projects.find((existingProject: Project) => existingProject.id === project.id)) {
            let index = this.model.projects.findIndex((existingProject: Project) => existingProject.id === project.id);
            this.model.projects[index] = project;
        }
        else {
            this.model.projects.push(project);
        }

        if (this.validateAdmin()) {

            this.updateAdminProject(project);
            this.setProjects(this.model.adminProjects);
        }
        else {
            this.setProjects(this.model.projects);
        }
    }

    updateAdminProject(project: Project): void {
        if (this.model.adminProjects.find((existingProject: Project) => existingProject.id === project.id)) {
            let index = this.model.adminProjects.findIndex((existingProject: Project) => existingProject.id === project.id);
            this.model.adminProjects[index] = project;
        }
        else {
            this.model.adminProjects.push(project);
        }

    }

    updateProject(app: App, project: Project): void {
        this._projectDataService.updateProject(app, project)
            .subscribe({
                next: (project: Project) => {
                    this.addUpdateProject(project);
                    this.setProject(project);
                },
                error: () => {
                    this._snackBar.open(`Error updating ${app.aliases.project.singular.toLocaleLowerCase()}`, 'Close', { duration: 2000 });
                },
                complete: () => { }
            });
    }

    publishProject(app: App, project: Project): void {
        this.model.savingOrPublishing = true;

        this._projectDataService.publishProject(app, project)
            .subscribe({
                next: (project: Project) => {
                    this._snackBar.open(`Layout published`, `Close`, { duration: 2000 });
                    this.model.project = project;

                    this.addUpdateProject(project);
                    this.setProject(project);
                },
                error: () => {
                    this._snackBar.open(`Error publishing layout`, `Close`, { duration: 2000 });
                },
                complete: () => { }
            })
            .add(() => { this.model.savingOrPublishing = false; });
    }

    updateProjectWithFilter(app: App, project: Project, filter: string[] = null): Observable<Project> {
        const project$ = new Subject<Project>();

        this._projectDataService.updateProjectWithFilter(app, project, filter)
            .subscribe({
                next: (project: Project) => {
                    this.addUpdateProject(project);
                    if (this.model.currentProject?.id === project.id || !this.model.currentProject?.id && project.active) {
                        if (!project.active) {
                            if (this.validateAdmin()) project = this.model.adminProjects.find((proj: Project) => proj.active);
                            else project = this.model.projects.find((proj: Project) => proj.active);
                        }
                        else {
                            if (this.validateAdmin()) project = this.model.adminProjects.find((proj: Project) => proj.id === project.id);
                            else project = this.model.projects.find((proj: Project) => proj.id === project.id);
                        }
                    }
                    else {
                        project = this.model.currentProject;
                    }
                    this.setProject(project);
                    project$.next(project);
                    this._snackBar.open(`${app.aliases.project.singular} Updated`, 'Close', { duration: 2000 });
                },
                error: () => {
                    this._snackBar.open(`Error updating ${app.aliases.project.singular.toLocaleLowerCase()}`, 'Close', { duration: 2000 });
                },
                complete: () => { }
            });

        return project$.asObservable();
    }

    validateProjectDeactivation(): boolean {
        let minimumActiveProject = 1;
        let shouldUseAdminProjects: boolean = this._router.url.includes('/admin');

        let projects = shouldUseAdminProjects ? this.model.adminProjects.filter(proj => proj.active) : this.model.projects.filter(proj => proj.active);
        if (projects.length === minimumActiveProject) {
            return false;
        }
        else if (projects.length > 0) {
            return true;

        }
        return false;
    }

    copyLayout(app: App, layoutTarget: Project): void {
        layoutTarget.JSONForms = this.model.currentProject.JSONForms;
        layoutTarget.layoutDraft = this.model.currentProject.layoutDraft;

        this._projectDataService.updateProject(app, layoutTarget)
            .subscribe({
                next: (project: Project) => {
                    this.addUpdateProject(project);
                    this.setProject(project);
                    this._snackBar.open(`Layout copied successfully`, 'Close', { duration: 2000 });
                },
                error: () => {
                    this._snackBar.open(`Error updating ${app.aliases.project.singular.toLocaleLowerCase()}`, 'Close', { duration: 2000 });
                },
                complete: () => { }
            });
    }

    projectChangedNotification(): void {
        this.projectChanged.next(true);
    }

    selectedProjectChangedNotification(): void {
        this.selectedProjectChanged.next(true);
    }

    dragAndDropNotification(): void {
        this.dragAndDropUpdate.next(true);
    }

    createItem(app: App, project: Project): void {
        this.model.savingOrPublishing = true;

        this._projectDataService.createItem(app, project)
            .subscribe({
                next: (project: Project) => {
                    this._snackBar.open(`${app.aliases.project.singular} saved`, "Close", { duration: 5000 });
                    this.addUpdateProject(project);
                    if (this.validateAdmin() && this.model.adminProjects.length === 1) this.setProject(this.model.adminProjects.filter(proj => proj.active)[0]);
                    else if (!this.validateAdmin() && this.model.projects.length === 1) this.setProject(this.model.projects.filter(proj => proj.active)[0]);
                },
                error: () => {
                    this._snackBar.open(`Error creating the ${app.aliases.project.singular.toLocaleLowerCase()}`, "Close", { duration: 5000 });
                }
            })
            .add(() => {
                this.model.savingOrPublishing = false;
            });
    }

    exportLayout(app: App, project: Project): void {
        this.model.exportingLayout = true;

        this._projectDataService.exportLayout(app, project)
            .subscribe({
                next: ((response) => {
                    let link = document.createElement("a");
                    link.setAttribute("href", URL.createObjectURL(response));
                    link.setAttribute("download", `${project.name}-ExportedLayout.txt`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }),
                error: (() => {
                    this._snackBar.open(`Layout export failed`, 'Close', { duration: 5000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.model.exportingLayout = false;
            });
    }

    uploadLayout(app: App, project: Project, formData: FormData): void {
        this.model.uploadingLayout = true;

        this._projectDataService.uploadLayout(app, project, formData)
            .subscribe({
                next: ((response) => {
                    this.addUpdateProject(response);
                    this.setProject(response);
                    this._snackBar.open(`Layout uploaded successfully`, 'Close', { duration: 5000 });
                }),
                error: (() => {
                    this._snackBar.open(`Layout upload failed`, 'Close', { duration: 5000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.model.uploadingLayout = false;
            });
    }

    addProjectAdmin(app: App, project: Project, userId: string): void {
        this.model.isSaving = true;

        this._projectDataService.addProjectAdmin(app, project, userId)
            .subscribe({
                next: (() => {
                    this._snackBar.open(`Project Admin added.`, `Close`, { duration: 5000 });
                    this.getProjectAdminsByProjId(app, project);
                }),
                error: (() => {
                    this._snackBar.open(`Unable to add project admin.`, `Close`, { duration: 5000 });
                }),
                complete: (() => { }),
            })
            .add(() => {
                this.model.isSaving = false;
            });
    }

    duplicateContents(uiSchema: FormElement): void {
        const rtsSection = this.model.currentProject.RTSForms.Sections.find((rtsSection: Section) => rtsSection.id === uiSchema.id);

        const parentSection = this.model.currentProject.JSONForms.Sections.find((parent: Section) => parent.id === rtsSection.parentId);

        const parentSectionDuplicate = new Section();

        this._rtsFormUtility.contents(parentSection, parentSectionDuplicate);

        this.model.currentProject.RTSForms.Sections.forEach((section: Section) => {
            if (section.id === rtsSection.id) {
                parentSectionDuplicate.Sections.forEach((dupe: Section) => {
                    section.Sections.push(dupe);
                });
            }
        });

        this._uiSchemaBuilderUtility.build(this.model.currentProject.RTSForms);

        this._schemaBuilderUtility.build(this.model.currentProject.RTSForms);
    }

    duplicateContentsRts(uiSchema: FormElement): void {
        const rtsSection = this.model.rtsForm.RTSForms.Sections.find((rtsSection: Section) => rtsSection.id === uiSchema.id);

        const parentSection = this.model.currentProject.JSONForms.Sections.find((parent: Section) => parent.id === rtsSection.parentId);

        const parentSectionDuplicate = new Section();

        this._rtsFormUtility.contents(parentSection, parentSectionDuplicate);

        this.model.rtsForm.RTSForms.Sections.forEach((section: Section) => {
            if (section.id === rtsSection.id) {
                parentSectionDuplicate.Sections.forEach((dupe: Section) => {
                    section.Sections.push(dupe);
                });
            }
        });

        this._uiSchemaBuilderUtility.buildRtsForm(this.model.rtsForm);

        this._schemaBuilderUtility.buildRtsForm(this.model.rtsForm);
    }

    validateAdmin(): boolean {
        let shouldUseAdminProjects: boolean = this._router.url.includes('/admin');
        return shouldUseAdminProjects;
    }

    doesAnyFormDataExist(): boolean {
        // eslint-disable-next-line
        for (let i = 0; i < this.model.projects.length; i++)
            if (this.model.projects[i].JSONForms?.Sections?.length > 0)
                return true;

        return false;
    }
}
