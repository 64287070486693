import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Color } from '@angular-material-components/color-picker';
import { UUID } from 'angular2-uuid';

import { ColumnTypes, ControlTypes, FieldTypes, GridFieldTypes, ObjectProperties, SelectOptions } from '.';
import { FieldType, FieldTypePrimitive, WafPrefix } from '../../shared/enums';
import { Column, Field, FieldProperty, Options, Section } from './models';

@Injectable({
    providedIn: 'root',
})
export class SmartsFormBuilderLogicService {
    sectionsIds: string[] = new Array<string>();
    columnsIds: string[] = new Array<string>();
    fieldsIds: string[] = new Array<string>();
    newGridIds: string[] = new Array<string>();
    listOfFieldProperty: Field[] = [];
    changedGridIds: string[] = new Array<string>();
    listOfColumns: Column[] = [];

    constructor(private _formBuilder: FormBuilder) { }

    isGroup(control: AbstractControl): control is FormGroup {
        return !this.isArray(control) && !this.isControl(control);
    }

    isControl(control: AbstractControl): control is FormControl {
        return control instanceof FormControl && !(control.value instanceof Array);
    }

    isArray(control: AbstractControl): control is FormArray {
        return control.value !== null && control.value instanceof Array;
    }

    isSection(control: AbstractControl): boolean {
        return this.isGroup(control) && control.value.hasOwnProperty(ObjectProperties.SectionTitle);
    }

    isColumnOrColumnGroup(control: AbstractControl): boolean {
        return this.isGroup(control) && control.value.hasOwnProperty(ObjectProperties.Fields);
    }

    isColumnGroup(control: AbstractControl): boolean {
        return this.isGroup(control) && control.value.hasOwnProperty(ObjectProperties.GroupTitle);
    }

    controlKeys(groupOrArray: FormArray | FormGroup): string[] {
        const controlKeys = new Array<string>();

        for (let controlKey in groupOrArray.controls) controlKeys.push(controlKey);

        return controlKeys;
    }

    addColumn(controlKey: string, formGroup: FormGroup): void {
        const newColumn: FormGroup = this._formBuilder.group({
            id: [UUID.UUID()],
            type: [ColumnTypes.Column],
        });

        newColumn.addControl(ControlTypes.Fields, this._formBuilder.array([]));

        (formGroup.get(controlKey) as FormArray).push(newColumn);
    }

    addColumnGroup(controlKey: string, formGroup: FormGroup): void {
        const newColumnGroup: FormGroup = this._formBuilder.group({
            id: [UUID.UUID()],
            type: [ColumnTypes.Group],
        });
        newColumnGroup.addControl(ColumnTypes.GroupTitle, new FormControl());
        newColumnGroup.addControl(ControlTypes.Fields, this._formBuilder.array([]));

        (formGroup.get(controlKey) as FormArray).push(newColumnGroup);
    }

    addSectionBeforeAllExistingSections(controlKey: string, formGroup: FormGroup): void {
        (formGroup.get(controlKey) as FormArray).insert(0, this.createNewSection());
    }

    addSectionAfterAllExistingSections(controlKey: string, formGroup: FormGroup): void {
        if ((formGroup.get(controlKey) as FormArray) === null)
            formGroup.addControl(ControlTypes.Sections, new FormArray([]));

        (formGroup.get(controlKey) as FormArray).push(this.createNewSection());
    }

    createNewSection(): FormGroup {
        const newSection: FormGroup = this._formBuilder.group({
            id: [UUID.UUID()],
            type: [ColumnTypes.Section],
            SectionTitle: ['', Validators.required],
            Columns: this._formBuilder.array([]),
            Sections: this._formBuilder.array([]),
        });

        return newSection;
    }

    addTextBox(controlKey: string, formGroup: FormGroup): void {
        const newTextBox = this.createField({ text: FieldTypes.TextBox }, FieldType.TextBox);
        (formGroup.get(controlKey) as FormArray).push(newTextBox);
    }

    addTextArea(controlKey: string, formGroup: FormGroup): void {
        const newTextArea = this.createField({ text: FieldTypes.TextArea }, FieldType.TextArea);
        (formGroup.get(controlKey) as FormArray).push(newTextArea);
    }

    addDateSelect(controlKey: string, formGroup: FormGroup): void {
        const newDateSelect = this.createField({ text: FieldTypes.DateSelect }, FieldType.DateSelect);
        (formGroup.get(controlKey) as FormArray).push(newDateSelect);
    }

    addCheckBox(controlKey: string, formGroup: FormGroup): void {
        const newCheckBox = this.createField({ text: FieldTypes.CheckBox }, FieldType.CheckBox);
        (formGroup.get(controlKey) as FormArray).push(newCheckBox);
    }

    addDropDown(controlKey: string, formGroup: FormGroup): void {
        const newDropDown = this.createField({ text: FieldTypes.DropDown }, FieldType.DropDown);
        (formGroup.get(controlKey) as FormArray).push(newDropDown);
    }

    addPhotoGallery(controlKey: string, formGroup: FormGroup): void {
        const newPhotoGallery = this.createField({ text: FieldTypes.PhotoGallery }, FieldType.PhotoGallery);
        (formGroup.get(controlKey) as FormArray).push(newPhotoGallery);
    }

    addUploader(controlKey: string, formGroup: FormGroup): void {
        const newUploader = this.createField({ text: FieldTypes.Uploader }, FieldType.Uploader);
        (formGroup.get(controlKey) as FormArray).push(newUploader);
    }

    addGrid(controlKey: string, formGroup: FormGroup): void {
        const newGrid = this.createField({ text: FieldTypes.Grid }, FieldType.Grid);
        (formGroup.get(controlKey) as FormArray).push(newGrid);
    }

    addNumber(controlKey: string, formGroup: FormGroup): void {
        const newNumber = this.createField({ text: FieldTypes.Number }, FieldType.Number);
        (formGroup.get(controlKey) as FormArray).push(newNumber);
    }

    addReadOnlyText(controlKey: string, formGroup: FormGroup): void {
        const newReadOnlyText = this.createField({ text: FieldTypes.ReadOnlyText }, FieldType.ReadOnlyText);
        (formGroup.get(controlKey) as FormArray).push(newReadOnlyText);
    }

    addReadOnlyTextArea(controlKey: string, formGroup: FormGroup): void {
        const newReadOnlyTextArea = this.createField({ text: FieldTypes.ReadOnlyTextArea }, FieldType.ReadOnlyTextArea);
        (formGroup.get(controlKey) as FormArray).push(newReadOnlyTextArea);
    }

    addURLHyperlink(controlKey: string, formGroup: FormGroup): void {
        const newURLHyperlink = this.createField({ text: FieldTypes.URLHyperlink }, FieldType.URLHyperlink);
        (formGroup.get(controlKey) as FormArray).push(newURLHyperlink);
    }

    addEmailHyperlink(controlKey: string, formGroup: FormGroup): void {
        const newEmailHyperlink = this.createField({ text: FieldTypes.EmailHyperlink }, FieldType.EmailHyperlink);
        (formGroup.get(controlKey) as FormArray).push(newEmailHyperlink);
    }

    addPerson(controlKey: string, formGroup: FormGroup): void {
        const newPerson = this.createField({ text: FieldTypes.Person }, FieldType.Person);
        (formGroup.get(controlKey) as FormArray).push(newPerson);
    }

    createField(fieldText: any = { text: '' }, selectOptions: FieldType): FormGroup {
        const newField: FormGroup = this._formBuilder.group({
            id: UUID.UUID(),
            Name: this.containsReadOnly(fieldText.text) ? [''] : ['', Validators.required],
            SelectOption: [selectOptions],
            repoId: [null],
            FieldType: this._formBuilder.group({
                id: [selectOptions, Validators.required],
                text: [fieldText.text],
                required: [false],
            }),
        });

        let fieldType: FormGroup = newField.get(ControlTypes.FieldType) as FormGroup;
        this.getFieldTypeControls(fieldType, selectOptions);
        return newField;
    }

    getFieldTypeControls(
        formGroup: FormGroup,
        sectionOptions: string,
        type = '',
        enumerator: string[] = [],
        options: Options = this.genericOptions()
    ): FormGroup {
        switch (sectionOptions) {
            case FieldType.TextBox:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                break;
            case FieldType.TextArea:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        multi: [options.multi],
                    })
                );
                break;
            case FieldType.DateSelect:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        format: [options.format],
                        dateFormat: [options.dateFormat],
                        dateSaveFormat: [options.dateSaveFormat],
                    })
                );
                break;
            case FieldType.CheckBox:
                type = SelectOptions.Bool;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        toggle: [options.toggle],
                    })
                );
                break;
            case FieldType.DropDown:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(SelectOptions.Enum, this._formBuilder.array(enumerator, Validators.required));
                break;
            case FieldType.PhotoGallery:
                formGroup.addControl(SelectOptions.Photos, this._formBuilder.control(true));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        height: [options.height],
                        arrows: [options.arrows],
                        endless: [options.endless],
                    })
                );
                break;
            case FieldType.Uploader:
                formGroup.addControl(SelectOptions.Upload, this._formBuilder.control(true));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        allowedExtensions: this._formBuilder.array(options.allowedExtensions),
                        maxFileSize: [options.maxFileSize],
                    })
                );
                break;
            case FieldType.Grid:
                formGroup.addControl(SelectOptions.Grid, this._formBuilder.control(true));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        columns: this._formBuilder.array(
                            options.columns.map((column: Column) => {
                                let temporaryColumn = this._formBuilder.group({
                                    id: [column.id],
                                    order: [column.order],
                                    header: [column.header, Validators.required],
                                    type: [column.type, Validators.required],
                                });
                                if (column.enum) {
                                    temporaryColumn.addControl(
                                        SelectOptions.Enum,
                                        this._formBuilder.array(column.enum)
                                    );
                                }
                                return temporaryColumn;
                            })
                        ),
                    })
                );
                break;
            case FieldType.Number:
                type = SelectOptions.Number;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                break;
            case FieldType.ReadOnlyText:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        readOnly: true,
                        displayText: [options.displayText, Validators.required],
                        displayColor: [
                            new Color(
                                options.displayColor.r,
                                options.displayColor.g,
                                options.displayColor.b,
                                options.displayColor.a
                            ),
                            Validators.required,
                        ],
                        bold: [options.bold],
                        italic: [options.italic],
                        size: [options.size],
                        alignment: [options.alignment],
                    })
                );
                break;
            case FieldType.ReadOnlyTextArea:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                formGroup.addControl(
                    SelectOptions.Options,
                    this._formBuilder.group({
                        readOnly: true,
                        displayText: [options.displayText, Validators.required],
                        multi: [options.multi],
                    })
                );
                break;
            case FieldType.URLHyperlink:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                break;
            case FieldType.EmailHyperlink:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                break;

            case FieldType.Person:
                type = SelectOptions.String;
                formGroup.addControl(SelectOptions.Type, this._formBuilder.control(type));
                break;

            default:
                break;
        }

        return formGroup;
    }

    genericOptions(): Options {
        const genericOptions: Options = {
            alignment: 'Center',
            allowedExtensions: [],
            arrows: true,
            bold: true,
            columns: [],
            dateFormat: 'MM/DD/YYYY',
            dateSaveFormat: 'YYYY-MM-DD',
            displayColor: new Color(66, 69, 83, 1),
            displayText: '',
            endless: true,
            format: 'date',
            height: '500px',
            italic: false,
            maxFileSize: 100000000,
            multi: true,
            readOnly: false,
            size: '1.6rem',
            toggle: true,
        };
        return genericOptions;
    }

    isFieldTypeReadOnlyText(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'ReadOnlyText');
    }

    isFieldTypeReadOnlyTextArea(fieldId): boolean {
        return this.compareFieldTypeToKey(fieldId, 'ReadOnlyTextArea');
    }

    isFieldTypeAReadOnlyField(fieldId): boolean {
        return this.isFieldTypeReadOnlyText(fieldId) || this.isFieldTypeReadOnlyTextArea(fieldId);
    }

    compareFieldTypeToKey(fieldId: string, key: string): boolean {
        return fieldId === FieldType[key];
    }

    containsReadOnly(text: string): boolean {
        return text.toLowerCase().includes('read only');
    }

    textBoxField(): FieldProperty {
        const textBox = new FieldProperty();
        textBox.id = FieldType.TextBox;
        textBox.text = FieldTypes.TextBox;
        textBox.type = FieldTypePrimitive.String;

        return textBox;
    }

    textAreaField(): FieldProperty {
        const textArea = new FieldProperty();
        textArea.id = FieldType.TextArea;
        textArea.text = FieldTypes.TextArea;
        textArea.type = FieldTypePrimitive.String;
        textArea.options = new Options();
        textArea.options.multi = true;

        return textArea;
    }

    dateSelectField(): FieldProperty {
        const dateSelect = new FieldProperty();
        dateSelect.id = FieldType.DateSelect;
        dateSelect.text = FieldTypes.DateSelect;
        dateSelect.type = FieldTypePrimitive.String;
        dateSelect.options = new Options();
        dateSelect.options.format = 'date';
        dateSelect.options.dateFormat = 'MM/DD/YYYY';
        dateSelect.options.dateSaveFormat = 'YYYY-MM-DD';

        return dateSelect;
    }

    checkBoxField(): FieldProperty {
        const checkBox = new FieldProperty();
        checkBox.id = FieldType.CheckBox;
        checkBox.text = FieldTypes.CheckBox;
        checkBox.type = FieldTypePrimitive.Boolean;
        checkBox.options = new Options();
        checkBox.options.toggle = true;

        return checkBox;
    }

    dropDownListField(): FieldProperty {
        const dropDownList = new FieldProperty();
        dropDownList.id = FieldType.DropDown;
        dropDownList.text = FieldTypes.DropDown;
        dropDownList.type = FieldTypePrimitive.String;
        dropDownList.enum = [];

        return dropDownList;
    }

    photoGalleryField(): FieldProperty {
        const photoGallery = new FieldProperty();
        photoGallery.id = FieldType.PhotoGallery;
        photoGallery.text = FieldTypes.PhotoGallery;
        photoGallery.photos = true;
        photoGallery.options = new Options();
        photoGallery.options.height = '500px';
        photoGallery.options.arrows = true;
        photoGallery.options.endless = true;

        return photoGallery;
    }

    uploaderField(): FieldProperty {
        const uploader = new FieldProperty();
        uploader.id = FieldType.Uploader;
        uploader.text = FieldTypes.Uploader;
        uploader.upload = true;
        uploader.options = new Options();
        uploader.options.allowedExtensions = [''];
        uploader.options.maxFileSize = 100000000;

        return uploader;
    }

    gridField(): FieldProperty {
        const grid = new FieldProperty();
        grid.id = FieldType.Grid;
        grid.text = FieldTypes.Grid;
        grid.grid = true;
        grid.options = new Options();
        grid.options.columns = [];

        return grid;
    }

    numberField(): FieldProperty {
        const numberField = new FieldProperty();
        numberField.id = FieldType.Number;
        numberField.text = FieldTypes.Number;
        numberField.type = FieldTypePrimitive.Number;

        return numberField;
    }

    readOnlyTextField(): FieldProperty {
        const readOnlyText = new FieldProperty();
        readOnlyText.id = FieldType.ReadOnlyText;
        readOnlyText.text = FieldTypes.ReadOnlyText;
        readOnlyText.menuDisplayText = 'Text';
        readOnlyText.type = FieldTypePrimitive.String;
        readOnlyText.options = new Options();
        readOnlyText.options.readOnly = true;
        readOnlyText.options.bold = true;
        readOnlyText.options.italic = false;

        return readOnlyText;
    }

    readOnlyTextAreaField(): FieldProperty {
        const readOnlyTextArea = new FieldProperty();
        readOnlyTextArea.id = FieldType.ReadOnlyTextArea;
        readOnlyTextArea.text = FieldTypes.ReadOnlyTextArea;
        readOnlyTextArea.menuDisplayText = 'Text Area';
        readOnlyTextArea.type = FieldTypePrimitive.String;
        readOnlyTextArea.options = new Options();
        readOnlyTextArea.options.readOnly = true;
        readOnlyTextArea.options.multi = true;

        return readOnlyTextArea;
    }

    urlHyperlinkField(): FieldProperty {
        const urlHyperlink = new FieldProperty();
        urlHyperlink.id = FieldType.URLHyperlink;
        urlHyperlink.text = FieldTypes.URLHyperlink;
        urlHyperlink.options = new Options();
        urlHyperlink.type = FieldTypePrimitive.String;

        return urlHyperlink;
    }

    emailHyperlinkField(): FieldProperty {
        const emailHyperlink = new FieldProperty();
        emailHyperlink.id = FieldType.EmailHyperlink;
        emailHyperlink.text = FieldTypes.EmailHyperlink;
        emailHyperlink.options = new Options();
        emailHyperlink.type = FieldTypePrimitive.String;

        return emailHyperlink;
    }

    personField(): FieldProperty {
        const person = new FieldProperty();
        person.id = FieldType.Person;
        person.text = FieldTypes.Person;

        return person;
    }

    fieldProperties(): FieldProperty[] {
        const fieldTypeOptions = new Array<FieldProperty>();

        fieldTypeOptions.push(this.textBoxField());
        fieldTypeOptions.push(this.textAreaField());
        fieldTypeOptions.push(this.dateSelectField());
        fieldTypeOptions.push(this.checkBoxField());
        fieldTypeOptions.push(this.dropDownListField());
        fieldTypeOptions.push(this.photoGalleryField());
        fieldTypeOptions.push(this.uploaderField());
        fieldTypeOptions.push(this.gridField());
        fieldTypeOptions.push(this.numberField());
        fieldTypeOptions.push(this.readOnlyTextField());
        fieldTypeOptions.push(this.readOnlyTextAreaField());
        fieldTypeOptions.push(this.urlHyperlinkField());
        fieldTypeOptions.push(this.emailHyperlinkField());
        fieldTypeOptions.push(this.personField());

        return fieldTypeOptions;
    }

    createSectionssId(): string {
        const uniqueId = UUID.UUID();
        this.sectionsIds.push(uniqueId);
        return uniqueId;
    }

    connectedSectionsIds(): string[] {
        return this.sectionsIds;
    }

    removeConnectedSectionsId(columnsIdtoRemove: string): void {
        this.sectionsIds = this.sectionsIds.filter((columnsId: string) => columnsId !== columnsIdtoRemove);
    }

    createColumnsId(): string {
        const uniqueId = UUID.UUID();
        this.columnsIds.push(uniqueId);
        return uniqueId;
    }

    connectedColumnsIds(): string[] {
        return this.columnsIds;
    }

    removeConnectedColumnsId(columnsIdtoRemove: string): void {
        this.columnsIds = this.columnsIds.filter((columnsId: string) => columnsId !== columnsIdtoRemove);
    }

    createFieldsId(): string {
        const uniqueId = UUID.UUID();
        this.fieldsIds.push(uniqueId);
        return uniqueId;
    }

    connectedFieldsIds(): string[] {
        return this.fieldsIds;
    }

    removeConnectedFieldsId(fieldsIdtoRemove: string): void {
        this.fieldsIds = this.fieldsIds.filter((fieldsId: string) => fieldsId !== fieldsIdtoRemove);
    }

    parseData(data: any): FormArray | FormGroup | [any] {
        if (Array.isArray(data)) {
            return this._formBuilder.array(data.map((item) => this.parseData(item)));
        }

        if (typeof data === 'object' && data !== null) {
            const formGroupContent = {};
            for (const [key, value] of Object.entries(data)) {
                formGroupContent[key] = this.parseData(value);
            }
            return this._formBuilder.group(formGroupContent);
        }

        return [data];
    }

    validateFormGroup(formGroup: FormGroup): boolean {
        if ((formGroup.value.FieldType.id === FieldType.TextBox || formGroup.value.FieldType.id === FieldType.TextArea)
            && (this.listOfFieldProperty.filter(x => x.id).map(x => x.id).includes(formGroup.value.id)
                && this.listOfFieldProperty.filter(x => x.id == formGroup.value.id && x.FieldType.id == FieldType.TextBox).map(x => x.FieldType.id).includes(FieldType.TextBox))) {

            return true;
        }
        return false;
    }

    validateFormGroupForGridTypes(gridColumn: FormGroup): boolean {
        if ((gridColumn.value.type === GridFieldTypes.Text || gridColumn.value.type === GridFieldTypes.TextArea)
            && (this.listOfColumns.filter(x => x.id).map(x => x.id).includes(gridColumn.value.id)
                && this.listOfColumns.filter(x => x.id == gridColumn.value.id && x.type == GridFieldTypes.Text).map(x => x.type).includes(GridFieldTypes.Text))) {

            return true;
        }
        return false;
    }

    addGridColumnFieldsToColumnList(formGroup: FormGroup): void {
        formGroup.value.columns.forEach((column: Column) => {
            this.listOfColumns.push(column);
        });
    }

    GetListOfFieldsFromSections(sections: Section[]): Field[] {
        let listOfFields: Field[] = [];
        const processSections = (sections: Section[]): void => {
            sections.forEach(section => {
                section.Columns.forEach(column => {
                    column.Fields.forEach(field => {
                        listOfFields.push(field);
                    });
                });

                if (section.Sections) {
                    processSections(section.Sections);
                }
            });
        };

        processSections(sections);

        return listOfFields;
    }

    AddWafPrefixfToFieldId(listOfFields: Field[]): Field[] {

        listOfFields.map(field => {
            switch (field.FieldType.id) {
                case FieldType.URLHyperlink:
                    field.id.includes(WafPrefix.Hyperlink) ? field.id = field.id : field.id = `${WafPrefix.Hyperlink}${field.id}`;
                    break;
                case FieldType.TextBox:
                case FieldType.TextArea:
                    field.id.includes(WafPrefix.RichText) ? field.id = field.id : field.id = `${WafPrefix.RichText}${field.id}`;
                    break;
                default:
                    field.id = field.id;
                    break;
            }
        });

        return listOfFields;
    }
}
