<h1
    [ngStyle]="{ color: '#582c82' }"
    mat-dialog-title
    class="khts-mat-dialog-title"
>
    Session timeout warning
</h1>
<div
    mat-dialog-content
    class="khts-mat-dialog-content"
>
    <p>
        Your session will expire in
        <countdown
            #cdTimer
            [config]="config"
        ></countdown
        >! Would you like to stay logged in?
    </p>
</div>
<div
    mat-dialog-actions
    class="khts-mat-dialog-actions"
    align="end"
>
    <button
        mat-raised-button
        [ngStyle]="{ backgroundColor: '#353a40', color: 'white' }"
        (click)="extendSessionClick()"
        cdkFocusInitial
    >
        Stay Logged In
    </button>
    <button
        mat-raised-button
        [ngStyle]="{ backgroundColor: '#fafafa', color: 'black' }"
        (click)="logoutClick()"
    >
        Log Out
    </button>
</div>
