import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NavigationUtilityService {

    isExpanded = false;

    constructor() { }

    init(): void {
        this.isExpanded = localStorage.getItem('smarts-nav-menu-expanded') === 'true';
    }

    expandCollapseNavMenu(): void {
        this.isExpanded = !this.isExpanded;
        localStorage.setItem('smarts-nav-menu-expanded', JSON.stringify(this.isExpanded));
    }

}
