
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AppsService, ProjectsService, SitesService, TokenService } from '.';
import { environment } from '../../environments/environment';
import { User } from '../models';
import { AuthDataService } from './data-services';
import { UsersLogicService } from './logic-services';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private _routedApp: string;
    private _codeLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    authenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showTOS: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    codeLoading$ = this._codeLoading.asObservable();
    loading$ = this.loading.asObservable();

    isAuthenticated = false;
    returnUrl: string | undefined = undefined;

    constructor(
        private _authData: AuthDataService,
        private _router: Router,
        private _msalService: MsalService,
        private _usersLogic: UsersLogicService,
        private _appsService: AppsService,
        private _projectsService: ProjectsService,
        private _sitesService: SitesService,
        private _snackBar: MatSnackBar,
        public tokenService: TokenService,
    ) {

        if (sessionStorage.getItem("authenticated") !== null) {
            let _authenticated = JSON.parse(sessionStorage.getItem("authenticated"));
            this.setAuthenticated(_authenticated);
        }

        this._msalService.handleRedirectObservable().subscribe({
            next: (response) => {
                if (response?.account) {
                    this._msalService.instance.setActiveAccount(response.account);
                    this._snackBar.open("Authenticated successfully.", "Close", { duration: 5000 });
                    this.loginUserAPI(response.accessToken);
                }
            },
            error: (error) => {
                console.log(error);
            },
        });
    }

    setRoutedApp(app: string): void {
        this._routedApp = app;
        sessionStorage.setItem("routedApp", app);
    }

    authenticateUserAD(): void {
        this.loading.next(true);

        let scope = {
            scopes: [environment.azure.scopes],
            account: this._msalService.instance.getActiveAccount()
        };

        let msal = this._msalService.acquireTokenSilent(scope).pipe(
            switchMap(x => {
                this.loginUserAPI(x.accessToken);
                this._msalService.instance.setActiveAccount(x.account);
                return of(true);
            }),
            catchError(() => {
                this._msalService.acquireTokenRedirect(scope);
                this.loading.next(false);
                return of(false);
            })
        );

        msal.subscribe((result: boolean) => {
            if (result)
                this._snackBar.open("Authenticated successfully.", "Close", { duration: 5000 });
        });
    }

    setAuthenticated(val: boolean): void {
        this.authenticated.next(val);
        this.isAuthenticated = val;
        sessionStorage.setItem("authenticated", val.toString());
    }

    private loginUserAPI(adToken: string): void {
        this._authData.loginUserAPI(adToken)
            .subscribe({
                next: (response) => {
                    if (response.token == "-1") {
                        this.loading.next(false);
                        this._snackBar.open("Your account is disabled, cannot log in", "Close", { duration: 5000 });
                        return;
                    }
                    else {
                        this.configureUser(response);
                    }
                },
                error: (error: any) => {
                    this.loading.next(false);
                    if (error.status === 401) {
                        this._router.navigate(['home'], { queryParams: { showRequestAccess: true } });
                        this._snackBar.open("No account found, please request access.", "Close");
                    }
                    else {
                        this._snackBar.open("The login failed. Please try again.", "Close", { duration: 5000 });
                    }
                }
            });
    }

    sendUserCode(phoneNumber: string): Observable<any> {
        const phoneNumberObject = { cellPhone: phoneNumber };
        this._codeLoading.next(true);

        const result$ = new Subject<any>();

        this._authData.sendUserCode(phoneNumberObject)
            .subscribe({
                next: (response: any) => {
                    result$.next(response);
                    result$.complete();
                }
            })
            .add(() => {
                this._codeLoading.next(false);
            });

        return result$.asObservable();
    }

    loginUserCode(cellPhone: string, code: string): void {
        const cellPhoneObject = { cellPhone: cellPhone, code: code };
        this.loading.next(true);

        this._authData.loginUserCode(cellPhoneObject)
            .subscribe({
                next: (response: User) => {
                    if (response.token == "-1") {
                        this.setReturnUrl(undefined);
                        this._snackBar.open("Your account is disabled, cannot log in", "Close", { duration: 5000 });
                        return;
                    }
                    else {
                        this.configureUser(response);
                    }
                },
                error: (error: any) => {
                    if (error.status === 401) {
                        this.setReturnUrl(undefined);
                        this._router.navigate(['home'], { queryParams: { showRequestAccess: true } });
                        this._snackBar.open("No account found, please request access.", "Close");
                    }
                    else {
                        this._snackBar.open("The login failed. Please try again.", "Close", { duration: 5000 });
                    }
                }
            })
            .add(() => {
                this.loading.next(false);
            });
    }

    configureUser(response: User): void {
        let token = response.token;
        this.tokenService.setToken(token);
        this._usersLogic.setUser(response);

        if (!response.acceptedTOS) {
            this.showTOS.next(true);
        }
        else {
            this.showTOS.next(false);
            this.setAuthenticated(true);
            this.redirectAfterLogin(response);
            this.loading.next(false);
        }
    }

    redirectAfterLogin(user: User): void {
        if (this.returnUrl) {
            let returnUrl = this.returnUrl;
            this.setReturnUrl(undefined);
            this._router.navigate([returnUrl]);
            return;
        }

        if (!user.isPlatformAdmin && !user.active) {
            this._router.navigate(['home']);
            return;
        }
        this._router.navigate(['platform/apps']);
        return;
    }

    logout(): void {
        sessionStorage.clear();
        this._usersLogic.disconnectUser();
        this._appsService.disconnectApp();
        this._projectsService.disconnectProject();
        this._projectsService.disconnectProjects();
        this._sitesService.disconnectSite();
        this._sitesService.disconnectSites();
        this.setReturnUrl(undefined);
        this.setAuthenticated(false);
        this._router.navigate(['home']);
    }

    redirectToHome(): void {
        this._router.navigate(['home']);
    }

    setReturnUrl(url: string): void {
        this.returnUrl = url;
    }

}
